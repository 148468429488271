import React, { Component } from 'react';

class Notfound extends Component {
  render() {
    return (
      <div>This is Notfound.</div>
    );
  }
}

export default Notfound;